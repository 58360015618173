<template>
	<div class="pupup_wrap" style="overflow: hidden">
		<div class="container">
			<div class="row">
				<div class="main_card_wrap ">
					<div class="main_card" :class="item_card.nft_card_div_code == 'NF00100005' ? 'main_card_type3' : 'main_card_type1'">
						<figure
							v-if="item_card.nft_card_div_code == 'NF00100005'"
						>
							<div class="frame frame_type3 thumbnail-wrapper">
								<div
									class="thumbnail"
								>
									<img
										:src="item_card.sl_img_url" alt="SPY">
								</div>
								<figcaption class="thumbnail_txt">
									<span class="name">{{  item_card.nft_card_grade_name }}</span>
									<em class="type">{{  item_card.nft_card_div_name }}</em>
									<strong class="price">{{  item_card.sl_coin_token_quantity | makeComma(8)  }} {{ text_unit }}</strong>
								</figcaption>
							</div>
						</figure>
						<div
							v-else
							class="gift_box"
						>
							<a
								class="gift_card mafia_card"
							>
								<div class="tit">
									<img
										:src="require('@/assets/image/gift_l.png')" alt="MAFIA CARD BUY"
									>
									<span>MAFIA CARD BUY</span>
								</div>
							</a>
						</div>
					</div>


					<div class="main_card main_card_type3">
						<div class="count mt-20">
							<button class="btn_count btn_count_down" @click="setCount(false)" :disabled="is_minus"><span class="hide">{{  $language.nft.sub }}</span></button>
							<input
								v-model="item_sale.purchase_quantity"
								type="number"
								readonly
							>
							<button class="btn_count btn_count_up" @click="setCount(true)" :disabled="is_plus"><span class="hide">{{  $language.nft.add }}</span></button>
						</div>
					</div>
				</div>
				<div class="drops_area mt-30">
					<div class="drops_list">
						<dl>
							<dt>{{  $language.nft.price }}</dt>
							<dd class="">{{ total_price | makeComma(8) }} {{ text_unit }}</dd>
						</dl>
						<dl
						>
							<dt>{{  $language.nft.final_payment }} {{ text_unit }}</dt>
							<dd class="col_red fw-700">{{  total_klay | makeComma(8) }} {{ text_unit }}</dd>
						</dl>
					</div>
					<div class="btn_area mt-20">
						<button
							v-if="max > 0"
							class="btn_l btn_fill_blue" @click="onPin"
						>{{ item_card.nft_card_div_name }}</button>
						<button
							v-else
							class="btn_l btn_fill_blue"
							disabled=""
						>SOLD OUT</button>
					</div>
				</div>
			</div>
		</div>

		<mafia0431
			v-if="item_result.coin_token_code"
			:user="user"
			:item_card="item_result"

			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 999; background-color: #0f1020"
		></mafia0431>

		<OTP
			v-if="is_otp"
			:options="otp_option"
			style="position: fixed; left: 0; top: 0; width: 100%; z-index: 101; background-color: white"

			@click="postSale"
			@cancel="is_otp = false"
		></OTP>

		<popup_confirm
			v-if="is_confirm"
			@click="toOtp"
			@cancel="is_confirm = false"
		>
			<template v-slot:title>{{  $language.auth.two_factor_info }}</template>
			<template
				v-slot:main-txt
			>{{  $language.auth.two_factor_status_non_register }}</template>
			<template
				v-slot:sub-txt
			>{{  $language.auth.two_factor_require_google }}</template>
			<template
				v-slot:name-cancel
			>{{  $language.common.cancel }}</template>
			<template
				v-slot:name-confirm
			>{{  $language.common.setting }}</template>
		</popup_confirm>
	</div>
</template>

<script>
import OTP from "@/view/Auth/mafia111-6"
import mafia0431 from "@/view/Drops/mafia043-1";
import popup_confirm from "@/view/Layout/PopupConfirm";
export default {
	name: 'mafia0392'
	, props: ['user']
	, components: {popup_confirm, mafia0431, OTP}
	, data: function () {
		return {
			program: {
				name: 'SaleDROPS'
				, type: 'popup'
				, title: this.$route.params.nft_card_div_code == 'NF00100005' ? 'UNION CARD' : 'MAFIA CARD'
			}
			, is_purchase: {
				nft: false
				, utility: false
			}
			, item_card: {
				sl_coin_token_code: ''
				, sl_coin_token_quantity: 0
				, sl_coin_token_name: ''
				, dc_before_sl_coin_token_quantity: 0
			}
			, item_sale: {
				purchase_quantity: 1
				, coin_token_quantity: 0
				, pin_number: ''
				, otp_number: ''
			}
			, item_result: {

			}
			, min: 1
			, max: 9999
			, is_otp: false
			, otp_option: {
				otp_type: 'check'
			}
			, is_confirm: false
			, blockchain_div_code: this.$route.params.blockchain_div_code
			, sl_coin_token_code: this.$route.params.sl_coin_token_code
			, math: null
			, item_nft: { }
		}
	}
	, computed: {
		total_price: function(){
			let t = 0
			t = this.item_card.sl_coin_token_quantity
			return t
		}
		, total_dc_klay: function(){
			let t = 0
console.log('this.item_card', this.item_card.dc_before_sl_coin_token_quantity)
			let d = ('' + this.item_card.dc_before_sl_coin_token_quantity).split('.')
			if(d.length > 1){
				let m = d[1].length
				t = this.item_card.dc_before_sl_coin_token_quantity * this.item_sale.purchase_quantity

				let dd = ('' + t).split('.')
				let mm = dd[1].length
				if(mm > m){
					t = t.toFixed(m)
				}
			}else{
				t = this.item_card.dc_before_sl_coin_token_quantity * this.item_sale.purchase_quantity
			}

			return t
		}
		, total_klay: function(){
			let t = 0

			let d = ('' + this.item_card.sl_coin_token_quantity).split('.')
			if(d.length > 1){
				let m = d[1].length
				t = this.item_card.sl_coin_token_quantity * this.item_sale.purchase_quantity

				let dd = ('' + t).split('.')
				let mm = dd[1].length
				if(mm > m){
					t = t.toFixed(m)
				}
			}else{
				t = this.item_card.sl_coin_token_quantity * this.item_sale.purchase_quantity
			}

			return t
		}
		, is_minus: function(){
			let t = false
			if(this.item_sale.purchase_quantity <= this.min){
				t = true
			}
			return t
		}
		, is_plus: function(){
			let t = false
			if(this.item_sale.purchase_quantity >= this.max){
				t = true
			}
			return t
		}
		, text_unit: function(){
			let t = ''
			switch((this.item_card.sl_coin_token_name).toLowerCase()){
				case 'klaytn':
					t = 'KLAY'
					break
				case 'eth':
					t = 'ETH'
					break
				case 'reap':
					t = 'REAP'
					break
				case 'maf':
					t = 'MAF'
					break
			}
			return t
		}
		, text_card_type_name: function(){
			let t = ''

			return t
		}
	}
	, methods: {
		getData: async function () {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_sales_card_info
					, data: {
						member_number: this.user.member_number
						, nft_sl_number: this.$route.params.nft_sl_number
						, blockchain_div_code: this.blockchain_div_code
						, sl_coin_token_code: this.sl_coin_token_code
					}
				})

				if (result.success) {
					this.item_card = result.data
					console.log('this.item_card', this.item_card)
					let t = this.item_card.sl_quantity - this.item_card.sl_compl_quantity
					if(this.max > t){
						this.max = t
					}
				} else {
					throw result.message
				}
			} catch (e) {
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, getNftConfig: async function(){

			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_drops_config
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})

				if(result.success){
					this.item_card = result.data
					this.item_card.sl_coin_token_list.forEach( coin => {
						if(coin.sl_coin_token_code == this.sl_coin_token_code){
							this.item_card.blockchain_div_code = coin.blockchain_div_code
							this.item_card.sl_coin_token_code = coin.sl_coin_token_code
							this.item_card.sl_coin_token_name = coin.sl_coin_token_name
							this.item_card.sl_coin_token_quantity = coin.sl_coin_token_quantity
							this.item_card.dc_before_sl_coin_token_quantity = coin.dc_before_sl_coin_token_quantity
						}
					})
					let t = this.item_card.sl_quantity - this.item_card.sl_compl_quantity
					if(this.max > t){
						this.max = t
					}
					console.log('this.item_nft', this.item_nft)
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postSale: function(otp_number){
			if(this.item_card.nft_card_div_code == 'NF00100005'){
				this.postUnionDrop(otp_number)
			}else if(this.item_card.nft_card_div_code == 'NF00100007'){
				this.postNftDrop(otp_number)
			}
		}
		, postUnionDrop: async function (otp_number) {
			try {

				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_sales_card
					, data: {
						member_number: this.user.member_number
						, pinnumber: this.item_sale.pin_number
						, gogle_otp_certfc_number: otp_number
						, nft_sl_number: this.$route.params.nft_sl_number
						, purchase_quantity: this.item_sale.purchase_quantity + ''
						, coin_token_code: this.item_card.sl_coin_token_code
						, coin_token_quantity: this.total_klay + ''
					}
				})

				if (result.success) {
					this.item_result = result.data
				} else {
					throw result.message
				}
			} catch (e) {
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
				this.is_otp = false
			}
		}
		, postNftDrop: async function(otp_number){
			let url = this.$api_url.api_path.post_nft_drop
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, pinnumber: this.item_sale.pin_number
						, gogle_otp_certfc_number: otp_number
						, nft_sl_number: this.$route.params.nft_sl_number
						, tot_purchase_quantity: this.item_sale.purchase_quantity
						, coin_token_code: this.item_card.sl_coin_token_code
						, tot_dc_before_sl_coin_token_quantity: this.total_dc_klay + ''
						, tot_purchase_coin_token_quantity: this.total_klay + ''
					}
					, type: true
				})

				if(result.success){
					this.item_result = result.data
					this.$set(this.item_result, 'coin_token_code', result.data.purchase_coin_token_code)
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_otp = false
			}
		}
		, toNft: function () {
			if (this.is_purchase.nft) {
				this.$emit('to', {name: 'mafia040', params: {card_type: 'nft'}})
			}
		}
		, toUtility: function () {
			if (this.is_purchase.utility) {
				this.$emit('to', {name: 'mafia040', params: {card_type: 'utility'}})
			}
		}
		, setCount: function(type){
			if(type){
				if(this.item_sale.purchase_quantity < this.max){
					this.item_sale.purchase_quantity++
				}else{
					this.item_sale.purchase_quantity = this.max
				}
			}else{
				if(this.item_sale.purchase_quantity > this.min){
					this.item_sale.purchase_quantity--
				}else{
					this.item_sale.purchase_quantity = this.min
				}
			}
		}
		, onPin: function(){
			if(this.user.gogle_otp_reg_fg == 'Y'){
				this.$bus.$emit('onPin', 'check')
				this.$bus.$on('pinCallback', (type, pin_number) => {
					this.item_sale.pin_number = pin_number
					this.onOtp()
				})
			}else{
				this.is_confirm = true
			}
		}
		, onOtp: function(){
			this.is_otp = true
		}
		, toOtp: function(){
			this.$bus.$emit('to', { name: 'mafia1113'})
		}
	}

	, created() {
		this.$emit('onLoad', this.program)

		if(this.$route.params.nft_card_div_code == 'NF00100005'){
			this.getData()
		}else{
			this.getNftConfig()
		}

		this.math = window.math
	}
}
</script>